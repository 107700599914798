<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

// 分配用户
export default {
  name: 'assignusers',
  data() {
    return {
      ...api.command.getState(),
      tenantId: null
    }
  },
  mounted() {
    this.tenantId = this.$route.query.tenantId
    this.tenantId &&
      api.command.getList.call(
        this,
        {
          url: '/system/farmTenantUser/page',
          current: 1,
          paramsValue: {
            host: '/bpi',
            tenantId: this.tenantId
          }
        },
        '/bpi'
      )
  },
  methods: {
    getHeader() {
      return [
        {
          name: '用户权限',
          key: 'roleName',
          type: 'input'
        },
        {
          name: '用户电话',
          key: 'phone',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-125',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'roleName',
          title: '用户权限',
          type: 'lt-250'
        },
        {
          dataIndex: 'phone',
          title: '用户电话',
          type: 'lt-250'
        },
        {
          title: '操作',
          type: 'buttonGroup',
          width: 60,
          typeData: ({ records }) => {
            return [
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.delPostArr
                    .call(this, {
                      url: '/system/farmTenantUser/delete',
                      params: {
                        ids: [records.id]
                      },
                      host: '/bpi'
                    })
                    .then(res => {
                      if (res.code == 200) {
                        this.$message.success(res.data)
                        this.getDataInit()
                      } else {
                        this.$message.error(res.msg)
                      }
                    })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.$router.push('/tenantManage/assignusersDetail?tenantId=' + this.tenantId)
          }
        }
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //         let ids = data.map(e => e.id)
        //         return api.command.delPostArr.call(this, {
        //           url: '/system/farmTenantUser/delete',
        //           params: {
        //             ids: [ids]
        //           },
        //           host: '/bpi'
        //         })
        //       }
        //     }
        //   ]
        // }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        tenantId={this.tenantId}
      />
    )
  }
}
</script>

<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    border-radius: 15%;
    margin-right: 10px;
  }
}
</style>
